@import url('https://fonts.googleapis.com/css?family=Roboto%20Condensed:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;700&display=swap');

@import url('./reset.css');
@import url('./animations.css');

@font-face {
  font-family: 'leinero';
  src: url('../assets/fonts/leinero.woff') format('woff');
  /* Pretty Modern Browsers */
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

title {
  font-size: 24px;
}

nav {
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  opacity: 1;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style: none;
}

h2 {
  font-weight: 300;
  font-size: 36px;
}

fieldset {
  border: none;
}

.flex {
  display: flex;
}

.desktop {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.mobile {
  display: none !important;
}

.d-none {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-hidden::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  title {
    font-size: 16px;
  }

  section {
    padding: 50px 20px;
  }

  .desktop,
  .d-flex {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

hr {
  border-top: 0;
  border-color: #cedae0;
}

.autofillFix:-webkit-autofill,
.autofillFix:-webkit-autofill:hover,
.autofillFix:-webkit-autofill:focus,
.autofillFix:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px #303435 inset;
  -webkit-text-fill-color: #e5e8ea;
  border-bottom: #dddddd solid 1px;
}

input:invalid,
input:-moz-ui-invalid {
  border: 0;
  outline: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* ----- 2022 ------------*/

.grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 60px);
  position: relative;
  overflow: hidden;

  width: 100%;
  margin-right: 5rem;
  padding-left: 5rem;
  
  font-family: 'Merriweather Sans', sans-serif;
  margin-top: 40px;
}

.alphabet-container {
  align-items: center;
  display: flex; 
  flex-direction: column;
}

.glossary-category-item {
  padding: 5px 30px;
  margin-left: 15px; 
  cursor: pointer;
  background-color: #d8d8d8;
  border-radius: 16px;
  height: 32px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.glossary-category-item.active {
  background-color: #303435;
  border: 1px solid black;
  color: white;
}

.glossary-alphabet-character {
  margin-top: 16px;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  cursor: pointer;
}

.glossary-alphabet-character.active {
  color: #006c9b;
  font-size: 24px;
}

.glossary-character-title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  margin-bottom: 16px;
}

.glossary-text-container {
  background-color: white;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 850px) {
  .grid-container {
    margin-right: 20px;
    padding-left: 20px;
  }

  .alphabet-container {
    flex-direction: row;
  }
  .alphabet-container div {
    margin-left: 10px;
  }
}


/* --------- CUSTOM MENU --------------*/
.swissre-header-container {
  background-color: #303435;
  position: relative;
  padding: 0 40px 0 40px;
  overflow: hidden;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  transition: all .5s;
}

.swissre-header-logo-container {
  flex: .1;
}

#menu-checkbox, .menu-hamburger-button {
  display: none;
}

.menu-hamburger-button {
  position: absolute;
  z-index: 5;
  right: 10px;
}

.swissre-help-routes, .swissre-languages-container {
  display: flex;
  align-items: center;
}

.swissre-help-routes  a {
  font: inherit;
  margin-right: 3em;
  cursor: pointer;
  color: #8b8e90;
}

.swissre-account-routes {
  display: flex;
  align-items: center;
}

.swissre-account-routes a{
  margin-right: 10px;
}

.swissre-languages-container{
  margin-right: 20px;
}

.swissre-help-routes.active a {
  color: white;
}

.swissre-navigation {
  display: flex;
  flex: 0.9;
  justify-content: space-between;
}

.swissre-language-selector {
  display: flex;
}

.swissre-my-profile {
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  align-items: center;
  color: #8b8e90;
}
.swissre-my-profile span{
  margin-right: 10px;  
}

@media only screen and (max-width: 850px) {
  .swissre-navigation {
    flex-direction: column;
  }
  
  .swissre-header-logo-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .swissre-help-routes, .swissre-account-routes  {
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .swissre-languages-container {
    margin-bottom: 20px;
  }

  .swissre-logins-container a {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .swissre-help-routes  a {
    margin-right: 0;
  }

  .swissre-header-container {
    background-color: #303435;
    display: flex;
    flex-direction: column;
    font-size: 25px;
  }

  .menu-hamburger-button {
    display: block;
  }

  #menu-checkbox:checked + .swissre-header-container {
    min-height: 360px;
  }
}

/* NEw to swiss page */
ol#lists {
  list-style: none;
  counter-reset: li;
}

ol#lists > li {
  padding-bottom: 64px;
  border-left: 8px solid #f7f7f7;
  padding-left: 36px;
  counter-increment: li;
}

ol#lists > li:first-child {
  margin-top: 64px;
  padding-top: -200px;
}

ol#lists > li:last-child {
  padding-bottom: 0;
  border-left: 0;
  padding-left: 36px;
  margin-left: 5px;
}

ol#lists > li > h2 {
  padding-bottom: 24px;
  margin-top: -24px;
}


ol#lists > li > p {
  font-family: 'Merriweather Sans';
  font-size: 16px;
  font-weight: 300;
  color: #242424;
}

ol#lists > li:before {
  content: counter(li);
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 6px 12px;
  background-color: #1babea;
  margin-left: -55px;
}

.nis-grid-content {
  background-color: white;
  padding: 36px 16px;
}

.nis-grid-content ul {
  margin-top: 35px;
}

.nis-grid-content ul li {
  margin-top: 16px;
}

.nis-grid-content ul li a {
  font-family: 'Merriweather Sans';
  font-size: 16px;
  font-weight: 300;
  color: #1babea;
}

.nis-grid-item {
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 250px;
  min-height: 370px;
}

.nis-details-page > div > ul {
  list-style-type: disc;
  margin-left: 100px;
}

.nis-details-page > div > ul > li {
  font-family: 'Merriweather Sans';
  font-weight: 300;
}

.nis-details-page > div > strong {
  display: block;
}

